<template>
	<div class="col-12 mt-2 mb-2">
        <div class="form-group">
            <div class="text-center">
                {{ validation_message }}
            </div>

            <div class="d-flex align-items-center justify-content-center">
                <b-form-checkbox
                    class="mr-3"
                    v-if="cgu_cgv_link || privacy_policy_link"
                    id="checkbox-cgu-cgv"
                    v-model="ack_cgu_cgv"
                    name="checkbox-cgu-cgv"
                    :disabled="readOnly"
                >
                    <a v-if="cgu_cgv_link" :href="cgu_cgv_link" target="_blank">{{ $t('monte.cgu_cgv') }}</a> <a v-if="privacy_policy_link" :href="privacy_policy_link" target="_blank">{{ $t('monte.privacy_policy') }}</a>
                </b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
	import Contract from "@/mixins/Contract.js"

	export default {
		name: "PrivacyPolicyAck",
		props: {
            acknowledged: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false }
		},
		mixins: [Contract],
		data () {
			return {
                validation_message: '',
                cgu_cgv_link: '',
                privacy_policy_link: '',

                ack_cgu_cgv: false,
            }
        },
        created() {
            this.preinit_component()
        },
        methods: {
            preinit_component() {
                this.contractPrivacyPolicy()
                    .then(res => {
                        if(!res) {
                            this.hasAcknowledged = true
                            return
                        }

                        // Si aucune des checkboxes à valider, on auto confirme
                        if(!res.cgv_link && !res.privacy_link) {
                            this.hasAcknowledged = true
                        }
                        
                        if(this.readOnly) {
                            this.ack_cgu_cgv = true
                        }

                        this.validation_message  = res.privacy_policy_content
                        this.cgu_cgv_link        = res.cgv_link
                        this.privacy_policy_link = res.privacy_link
                    })
            },

            checkHasAck() {
                const cond_cgu_cgv = !this.cgu_cgv_link || (this.cgu_cgv_link && this.ack_cgu_cgv)
                this.hasAcknowledged = cond_cgu_cgv
            }
        },
        computed: {
            hasAcknowledged: {
                get() {
                    return this.acknowledged
                },
                set(val) {
                    this.$emit('update:acknowledged', val)
                }
            }
        },
        watch: {
            ack_cgu_cgv() {
                this.checkHasAck()
            },
            readOnly(val) {
                if(val === true) {
                    this.ack_cgu_cgv = true
                }
            }
        }
    }
</script>